.page-content{
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

.main-small{
  font-size: 0.625em;
  line-height: 1.3333em;
  @include tracking(10);
  @include mq(md){
    font-size: 0.75em;
    line-height: 1.3333em;
  }
}
.main-ital{
  @include font(main-ital);
  font-size: 1.2em;
  line-height: 1.143em;
  @include tracking(20);
}
.main-big{
  font-size: 1em;
  @include tracking(10);
  @include mq(md){
    font-size: 1.2em;
    line-height: 1.333em;
  }
}

em{
  @extend .main-ital;
}
// ——————————————— BLOCKQUOTES ——————————————— //

.block-type-quote:first-of-type{
margin-top: 0.75em;
}


blockquote{
  font-size: 0.85em;
  line-height: 1.3333em;
  text-align: center;
  margin: 0 25px 2.85rem 25px;
  @include mq(md){
    font-size: 1em;
    max-width: 52%;
    margin: 0 auto 4.75rem auto;

  }
}

blockquote footer{
  margin-top: 0.3em;
  font-size: 0.75em;
  line-height: 1.333em;
  @include mq(md){
    font-size: 0.75em;
    line-height: 1.3333em;
  }
}

// ——————————————— TITLE ——————————————— //

h1{
  text-align: center;
  @include font(title);
  @include fs(title);
  @include tracking(20);
  font-weight: normal;
  margin-bottom: 0.6em;
  margin-top: 0.62em;
  @include mq(md){
    margin-top: 0.67em;
    margin-bottom: 0.8em;
  }
}

h2{
  @extend .main-big;
  text-align: center;
  font-weight: normal;
}

// ——————————————— IMAGE ——————————————— //

.block-type-image{
  margin: 3.2rem auto 3.2rem auto;
  @include mq(md){
    margin: 3.5rem auto;
  }
}
.block-type-image{
  picture{
    margin: 0 auto;
    display: block;
  }
  picture.landscape{
    max-width: 100%;
    @include mq(md){
      max-width: 70%;
    }
  }
  picture.portrait{
    max-width: 80%;
    @include mq(md){
      max-width: 50%;
    }
  }
}

.image-caption{
  @extend .main-small;
  max-width: 450px;
  margin: 0.5em auto 0;
  text-align: center;
}

// ——————————————— VIDEO ——————————————— //

.block-type-video{
  margin: 3.2rem auto 3.2rem auto;
  @include mq(md){
    margin: 3.5rem auto;
  }

  figure{
    margin: 0 auto;
  }

  figure.landscape{
    max-width: 100%;
    @include mq(md){
      max-width: 70%;
    }
  }

  figure.portrait{
    max-width: 80%;
    @include mq(md){
      max-width: 50%;
    }
  }

  figcaption{
    @extend .main-small;
    max-width: 450px;
    margin: 0.5em auto 0;
    text-align: center;
  }

  video{
    object-fit: contain;
    width: 100%;
  }
}

// ——————————————— TEXT ——————————————— //

.block-type-markdown{
  max-width: 100%;
  @include mq(md){
    max-width: 52%;
    margin-left: calc(48% / 2 + 1%);
  }
  p+p{
    text-indent: 1.5em;
  }
  hr{
    display: block;
    height: 1em;
    border: none;
    visibility: hidden;
  }
  h3, h4{
    @extend .underline;
    font-weight: normal;
    font-size: 1em;
    text-align: center;
    margin-top: 1em;
  }
  h3{
    margin-top: 1em;
  }
  h5{
    @extend .main-ital;
    font-weight: normal;
    text-align: center;
    margin-top: 0.9em;
  }

  li{
    margin-left: 1.5em;
    text-indent: -1.5em;
  }

  *:last-of-type{
    margin-bottom: 0;
  }
}