#fullpage .section{
  display: block;
  .fp-overflow{
    height: 100%;
  }
}
#fullpage .section:first-of-type.fp-table{
  display: block;
  height: 100% !important;
  @include mq(md){
    height: calc(100% - var(--hh)) !important;
  }
  .fp-overflow{
    height: 100%;
    display: flex;
    flex-direction: column;
    @include mq(md){
      display: unset;
    }
  }
  .logo{
    position: static;
    z-index: 9;
    @include mq(md){
      top: unset;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.fp-watermark{
  display: none;
}