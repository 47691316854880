.page-content {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5.1rem;
  padding-bottom: 3.25rem;
  max-width: 900px;
  @include mq(md) {
    padding-top: 8.1rem;
    padding-bottom: 4rem;
    padding-left: 0;
    padding-right: 0;
  }
}