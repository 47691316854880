body#contact .block-type-markdown{
  @extend .main-big;
  text-align: center;
  margin: 0;
  max-width: 100%;
  a{
    color: black;
    @include mq(md){
      color: #7c7c7b;
    }
  }
}

body#contact .block-type-heading{
}

body#contact .page-content{
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--hh));
}

.copyrights{
  @extend .main-small;
  margin-top: auto;
  margin-bottom: 0;
  text-align: center;
  a:hover{
    color: #7c7c7b;
  }
}