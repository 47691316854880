.collapsible {
  position: absolute;
  left: 20px;
  cursor: pointer;
  z-index: 999999999;
  display: inline-block;
  @include mq(md) {
    display: none;
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.content ul:first-of-type{
  padding-top: 0;
  @include mq(md){
    margin-top: unset;
  }
}
.content {
  left: 0;
  padding: 0;
  background-color: white;
  max-height: 0;
  height: unset;
  overflow: hidden;
  position: fixed;
  top: 0px;
  z-index: 9;
  width: 100%;
  @include mq(md) {
    height: auto;
    max-height: unset;
    overflow: visible;
    position: static;
    padding: 0;
  }
}

.content > ul{
  padding-top: 92px !important;
  @include mq(md){
    padding-top: 0 !important;
  }
}
.collapsible + .content{
  .lang-menu{
    display: none !important;
    @include mq(md){
      display: flex !important;
    }
  }
}
.collapsible.active + .content{
  overflow: visible;
  .lang-menu{
    display: block !important;
  }
}

.collapsible {
  height: 25px;
  i{
    display: block;
    position: relative;
    top: 2px;
    height: 100%;
  }
  i:before {
    content: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='45' height='26' viewBox='0 0 45.19 26.71'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B clip-path: url(%23clippath); %7D .cls-2 %7B fill: none; %7D .cls-2, .cls-3 %7B stroke-width: 0px; %7D .cls-3 %7B fill: %231d1d1b; %7D %3C/style%3E%3CclipPath id='clippath'%3E%3Crect class='cls-2' x='.1' y='.1' width='45' height='26.5'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='cls-1'%3E%3Crect class='cls-3' x='.1' y='.1' width='45' height='1'/%3E%3Crect class='cls-3' x='.1' y='12.85' width='45' height='1'/%3E%3Crect class='cls-3' x='.1' y='25.6' width='45' height='1'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.collapsible.active {
  i {
    content: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='45' height='26' viewBox='0 0 45.19 26.71'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B clip-path: url(%23clippath); %7D .cls-2 %7B fill: none; %7D .cls-2, .cls-3 %7B stroke-width: 0px; %7D .cls-3 %7B fill: %231d1d1b; %7D %3C/style%3E%3CclipPath id='clippath'%3E%3Crect class='cls-2' x='6.6' width='32' height='26.71'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='cls-1'%3E%3Crect class='cls-3' x='2.25' y='12.85' width='40.7' height='1' transform='translate(-3.33 17.46) rotate(-39.58)'/%3E%3Crect class='cls-3' x='22.1' y='-7' width='1' height='40.7' transform='translate(-2.09 22.26) rotate(-50.41)'/%3E%3C/g%3E%3C/svg%3E");
  }
}