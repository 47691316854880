// CONFIGURATE DOCUMENT

$fontsize: (
        main: (
                xs: (
                        fs: 18px,
                        lh: 1.333em,
                ),
                md: (
                        fs: 21px,
                        lh: 1.3333em,
                ),
        ),
        title: (
                xs: (
                        fs: 17px,
                        lh: 1.2em,
                ),
                md: (
                        fs: 23px,
                        lh: 1.2em,
                ),
        ),
);

$margin: (
        sm: 1,
);

:root {
  font-size: 16px;
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
        main: (
                name: garamondmm-eqregular,
                fallback: "serif",
                withFile: true,
        ),
        main-ital: (
                name: fournier-mt-italic-old-style-figures,
                fallback: "serif",
                withFile: true,
        ),
        title: (
                name: egyptian-710,
                fallback: "serif",
                withFile: true,
        ),
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
        xs: 0px,
        sm: 576px,
        md: 1024px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
