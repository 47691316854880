.carousel{
  padding: 0rem 25px 25px;
  max-width: 1728px;
  margin: 0 auto;
  width: 100%;
  @include mq(md){
    padding: 10.5rem 1.563rem 1rem;
  }
}

.carousel, .flickity-viewport{
  height: 100% !important;
}

.carousel-cell{
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  @include mq(md){
    width: 50%;
  }
  picture, img{
    height: 100%;
  }
  img, video{
    object-fit: contain;
    object-position: top;
    @include mq(md){
      object-position: center;
    }
  }
}

.carousel-cell{
  display: flex;
  picture.landscape, video.landscape{
    width: 100%;
    @include mq(md){
      width: 90%;
    }
  }
  picture.portrait, video.portrait{
    width: 80%;
    @include mq(md){
      width: 100%;
    }
  }
}
.carousel-cell.odd{
  justify-content: center;
  picture.portrait img, video.portrait{
    object-position: top center;
    @include mq(md){
      object-position: top left;
    }
  }
  @include mq(md){
    justify-content: start;
  }
}

.carousel-cell.even{
  justify-content: center;
  picture.portrait img, video.portrait{
    object-position: top center;
    @include mq(md){
      object-position: top right;
    }
  }
  @include mq(md){
    justify-content: end;
  }
}

.flickity-button{
  height: 100% !important;
  width: calc(50% - calc(1.563rem / 2)) !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  position: absolute !important;
  opacity: 0.5;
  z-index: 9999;
  @include mq(md){
    height: 100% !important;
  }
  .flickity-button-icon{
    display: none !important;
    @include mq(md){
      display: none !important;
      top: 50% !important;
      transform: translateY(-50%);
      width: auto !important;
    }

  }
  svg{
    height: 100px !important;
    top:0 !important;
  }
  svg path{
    fill: black;
    position: absolute;
  }
}
.flickity-button:focus {
  outline: none !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

.flickity-prev-next-button.next{
  left: calc(50% - calc(1.563rem / 2));
  cursor: e-resize;
  border-radius: 0;
  .flickity-button-icon{
    left: unset !important;
    right: 0;
    display: none;
  }
}

.flickity-prev-next-button.previous{
  left: 0 !important;
  cursor: w-resize;
  border-radius: 0;
  .flickity-button-icon{
    left: 0 !important;
  }
}

.slideshow-legend{
  position: absolute;
  top: -1.4rem;
  left:0;
  color: black;
  padding: 0 0.75rem;
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: center;
  @include mq(md){
    position: unset;
    display: flex;
    padding: 0;
    text-align: center;
    width: auto;
    color: green;
    left:0;
    top: unset;
  }
  a{
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: black !important;

    @include mq(md){
      display: block;
      width: max-content;
      margin-left: 1.1333333333333333em;
      color: green !important;
    }
    span{
      margin-left: auto;
      @include mq(md) {
        margin-left: 1.13em;
      }
    }
  }
  a.buy{
    span{color: red !important;}
  }
  &.has-background{
    a.buy{
      span{color: black !important;}
      @include mq(md){
        span{color: red !important;}
      }
    }
  }
}