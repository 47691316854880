body#presse .page-content{
  padding-top: 5.6rem;
  @include mq(md){
    padding-top: 9rem;
  }
}

.press-item{
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}

.press-item-media{
  @extend .main-big;
  p{
    @extend .main-ital;
  }
}

.press-item h2 a{
  color: #7c7c7b;
  margin-bottom: 1em;
  display: inline-block;
  &:hover{
    color: black !important;
  }
}