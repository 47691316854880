
#commandes .page-content{
  padding-top: 5.1rem;
  position: relative;
  @include mq(md){
    padding-top: 3rem;
  }
}

h1.page-title{
  margin-bottom: 2.8em;
  @include mq(md){
    display: none;
  }
}

.sub-menu{
  text-align: center;
  margin: 0 auto;
  @extend .main-big;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-row-gap: 2em;
  justify-content: center;
  @include mq(md){
    display: block;
    max-width: unset;
  }
  a:last-of-type{
    grid-column: span 2;
    display: inline-block;
    @include mq(md){
      display: unset;
      margin-top: unset;
    }
  }
  a{
    margin: 0 0.5em;
  }
  a.active{
    @extend .main-ital;
    @include notouch(){
      &:hover{
        text-decoration: none;
      }
    }
  }
  a{
    @include notouch(){
      &:hover{
        /*text-decoration: underline;
        text-underline-offset: 0.15em;
        text-decoration-thickness: 1px;*/
      }
    }
  }

}

.intro-text{
  margin-top: 2.5em;
}


.block-type-command{
  text-align: center;
  h1{
    margin-top: 2.8em;
    margin-bottom: 1.4em;
  }
  p{
    @extend .main-big;
    margin-bottom: 1em;
  }
  em{
    @extend .main-ital;
  }
  a{
    color: #7c7c7b;
    &:hover{
      color: black !important;
    }
  }
}

.product-grid{
  display: grid;
  margin-top: 2.4em;
  margin-bottom: 3.8em;
  grid-template-columns: repeat(2,1fr);
}

.product-item{
  margin-bottom: 2.4em;
  p{
    margin-bottom: 0;
    margin-top: 0;
  }
  p:first-of-type{
    font-size: 0.75em;
    line-height: 1.3333em;
    @include mq(md){
      font-size: 1em;
      line-height: 1.3333em;
    }
  }
  p:last-of-type{
    font-size: 0.75em;
    line-height: 2em;
    @include mq(md){
      font-size: 1em;
      line-height: 1.3333em;
    }
  }
}

.product-image{
  width: 75%;
  margin: 0.4em auto 0;
  @include mq(md){
    width: 50%;
    margin: 0.6em auto 0;
  }
  img{
    object-fit: cover;
    aspect-ratio: 7/10;
  }
}

.command-list{
  opacity: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;
  left:0;
  padding: 0 20px;
  @include mq(md){
    left:unset;
    padding: unset;
  }
  &.show{
    height: auto;
    overflow: auto;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.command-list:not(#domaine) p:last-of-type{
  margin-bottom: 2.425em;
}

.buttons{
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 2.7em;
  @include mq(md){
    margin-bottom: 3.3em;
  }
  a{
    color: #7c7c7b;
  }
  @include notouch(){
    /*a:hover{
      color: black;
    }*/
  }
}

.button{
  cursor: pointer;
  padding: 0.4em 0;
  @extend .main-big;
  border: 1px solid #7c7c7b;
  @include mq(md){
    padding: 0.2em 0;
  }
  &:hover{
    color: black;
    border-color: black;
  }
}
.buttons a:first-of-type .button{
  margin-bottom: 0.5em;
}
