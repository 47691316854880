*::-webkit-scrollbar{
  display: none;
}

*{
  scrollbar-width: 0px;
}

*::selection{
  background-color: #00000012;
  color: black !important;
}

html, body{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body main{
  overflow: hidden !important;
  padding-bottom: 0;
}

main{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.underline{
  text-decoration: underline;
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}



